.animationZeroDuration * {
	transition-duration: 0s !important;
}

* {
	font-family: 'Prater';
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none; /* Likely future */
	box-sizing: border-box;
}
input,
select,
textarea {
	-webkit-user-select: all; /* Chrome all / Safari all */
	-moz-user-select: all; /* Firefox all */
	-ms-user-select: all; /* IE 10+ */
	user-select: all;
}
button {
	border:0;
	background-color: transparent;
}

.av-playerContainer {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	position: absolute;
	background-color: transparent;
	overflow: visible;
}
img.print {
	display: none;
}

.hidden-seo {
	opacity: 0;
	height: 0;
	width: 0;
	min-height: 0;
	min-width: 0;
	max-height: 0;
	max-width: 0;
	position: absolute;
	left: -999em;
}

body:not(.standardCursor) {
	cursor: url('/static_kkn/global/cursor/auto.cur'), auto;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

canvas.kkn-mouse-text {
	cursor: url('/static_kkn/global/cursor/pointer.cur'), auto;
}

canvas.kkn-mouse-pointer {
	cursor: url('/static_kkn/global/cursor/pointer.cur'), auto;
}
html.kkn-pressed canvas.kkn-mouse-pointer,
html.kkn-pressed body {
	cursor: url('/static_kkn/global/cursor/click.cur'), auto !important;
}

.hidden {
	display: none !important;
}

.inv {
	top: -999em !important;
}

.animation {
	transition: all 0.6s ease-out;
}
.isvg {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}
.isvg svg {
	display: flex;
}

button,
button:focus {
	outline: 0;
}

svg > g {
	display: none;
}
ul,
ul li,
ol li,
ol {
	padding: 0;
	margin: 0;
	line-height: 1.5;
}

body,
html {
	padding: 0;
	margin: 0;
	width: 100%;
	height: 100%;
	background-color: #ffc300;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}
html.kkn-right body {
	justify-content: flex-start;
}
